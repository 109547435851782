import React from "react";
import { Layout, Menu, Icon, Dropdown, Avatar } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
import logo from "../../../../assets/images/logos/logohead.svg";
// import { ReactComponent as bio } from "../../../../assets/images/icons/bio-icon.svg";
import { ReactComponent as Snapshot } from "../../../../assets/images/icons/snapshot-icon.svg";
import { ReactComponent as linkIcon } from "../../../../assets/images/icons/navLink.svg";
import { ReactComponent as setting } from "../../../../assets/images/icons/setting-icon.svg";
// import { ReactComponent as lead } from "../../../../assets/images/icons/leads.svg";
// import { ReactComponent as report } from "../../../../assets/images/icons/reporting.svg";
import { ReactComponent as droparrow } from "../../../../assets/images/icons/down-arrow.svg";
// import { ReactComponent as InfoCircle } from '../../../../assets/images/icons/info-cirlce-o.svg';

const { Header } = Layout;
const { Item } = Menu;

const Navbar = props => {
  const { user, domain } = props;
  const { user_fname } = user;
  // let showBonuses = false;
  // if (
  //   domain.role === "owner" &&
  //   (user.origin === "JVZOO" || user.origin === "THRIVECART")
  // ) {
  //   showBonuses = true;
  // }

  const userDropdownMenu = (
    <Menu className="navbar-user-dropdown">
      {/* <Item key="account">
        <div className="current-account-details">
          <span className="account-name">
            {domain && domain.dom_name
              ? domain.dom_name
              : user_fname.split(" ")[0] + "'s Domain"}
          </span>
          <Tag color="#FF8200">{user.user_domains[domain.id]["role"]}</Tag>
        </div>
      </Item> */}
      <Item key="plan">Plan: {props.plan.plan_name}</Item>
      <Item key="profile_settings">
        <Link to="/settings">
          Profile Settings <Icon type="user" />
        </Link>
      </Item>
      {/* {showBonuses && (
        <Item key="bonuses">
          <Link to="/bonus">
            Bonuses <Icon type="gift" />
          </Link>
        </Item>
      )} */}
      {/* <Item key="billing"><Link to={`/billing`}>Billing <Icon type="dollar" /></Link></Item>
            <Item key="facebook_group"><a href="https://www.facebook.com/groups/conversionhackersclub" target="_blank" rel="noreferrer noopener">Facebook Group <Icon type="facebook" /></a></Item> */}
      {/* <Item key="add_account">
        <Link to="/onboarding">
          Add Account <Icon type="usergroup-add" />
        </Link>
      </Item>
      {(plan.plan_features.DOM_COUNT > 1 ||
        plan.plan_features.DOM_COUNT === -1) && (
        <Item key="manage_accounts">
          <Link to="/manage-accounts">
            Manage Accounts <Icon type="usergroup-add" />
          </Link>
        </Item>
      )}
      {user.origin && user.origin === "PG" && (
        <Item key="activation_codes">
          <Link to="/activation-codes">
            Activation Codes <Icon type="key" />
          </Link>
        </Item>
      )} */}
      <Item key="signouy">
        <Link to="/logout">
          Sign Out <Icon type="poweroff" />
        </Link>
      </Item>
    </Menu>
  );

  return (
    <Header className="navbar">
      <div className="logo">
        <Link to={`/${domain.id}`}>
          <img src={logo} alt="logo-head" />
        </Link>
      </div>

      <Menu className="left-menu" theme="dark" mode="horizontal">
        <Item key={1}>
          <Link to={`/${domain.id}`}>
            <Icon component={Snapshot} /> Snapshot
          </Link>
        </Item>
        <Item key={3}>
          <Link to={`/${domain.id}/timers`}>
            <Icon component={linkIcon} /> Timers
          </Link>
        </Item>
        <Item key={6}>
          <Link to={`/${domain.id}/pixel`}>
            <Icon component={setting} /> Pixel
          </Link>
        </Item>
      </Menu>

      <Menu className="right-menu" theme="dark" mode="horizontal">
        {/* <Item key={7}>
                    <Dropdown placement="bottomCenter" overlay={
                        <Menu className="navbar-user-dropdown">
                            <Item key="help_ticket"><a href="https://help.Timerly.com/portal/newticket" target="_blank" rel="noreferrer noopener">Submit Ticket</a></Item>
                            <Item key="help_kb"><a href="https://help.Timerly.com/portal/kb" target="_blank" rel="noreferrer noopener">Knowledge Base</a></Item>
                        </Menu>
                    } trigger={['hover']}>
                        <a className="ant-dropdown-link" href="#/" style={{ fontSize: "1.05rem" }}>
                            <Icon component={InfoCircle} style={{ margin: "-5px 5px 0 0" }} /> Help
                        </a>
                    </Dropdown>
                </Item> */}

        <Item key={7}>
          <Dropdown overlay={userDropdownMenu} trigger={["hover"]}>
            <a className="ant-dropdown-link" href="#/">
              <Avatar
                style={{ backgroundColor: "#FC4747", verticalAlign: "middle" }}
                size="large"
              >
                {user_fname[0]}
              </Avatar>

              <span className="avatar-details">
                {user_fname}
                <br />
                <span className="domain-name">
                  {domain && domain.dom_name
                    ? domain.dom_name
                    : user_fname.split(" ")[0] + "'s Domain"}
                </span>
              </span>
              <Icon component={droparrow} />
            </a>
          </Dropdown>
        </Item>
      </Menu>
    </Header>
  );
};

export default Navbar;
