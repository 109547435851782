import React from 'react';
import { message } from 'antd';
import './index.scss';

class ClickToCopy extends React.Component {
    state = {
        copySuccess: this.props.defaultText ? this.props.defaultText : "Copy"
    }

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        message.success("Copied Successfully!");
    };

    render() {
        return (
            <span>
                {
                    document.queryCommandSupported('copy') &&
                    <span>
                        <a href="#/" onClick={this.copyToClipboard} style={{ color: "inherit" }}>
                            {this.props.children}
                        </a>

                        <textarea
                            style={{ width: 0, height: "0 !important", padding: 0, margin: 0, borderColor: "transparent", lineHeight: 0, outline: 'none', background: 'transparent' }}
                            ref={(textarea) => this.textArea = textarea}
                            value={this.props.textToCopy}
                            readOnly
                        />
                    </span>
                }
            </span>
        );
    }
}

export default ClickToCopy;