import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { Row, Col, Form, Icon, Input, Button, Alert, message } from 'antd';
import { resetPassword } from '../../../helpers/ajax/authAjax';
import FormContainer from '../FormContainer';
import SideColumn from '../SideColumn';

class ResetPassword extends Component {
    state = {
        success: '',
        error: '',
        loading: false,
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (values.password !== values.cpassword) {
                    this.setState({
                        error: 'Passwords do not match!'
                    });
                    return;
                }
                this.setState({ loading: true }, () => {
                    resetPassword(this.props.match.params.hash, values.password)
                        .then((res) => {
                            if (res.data.success === 1) {
                                message.success('Password Reset Successfull!');
                                this.props.history.push('/login');
                            } else {
                                this.setState({
                                    error: res.data.msg,
                                    success: '',
                                    loading: false,
                                })
                            }
                        }).catch(e => {
                            this.setState({
                                error: e.message,
                                success: '',
                                loading: false,
                            })
                        })
                })
            }
        });
    }

    componentDidMount() {
        document.title = this.props.title;
    }
    componentWillReceiveProps(props) {
        document.title = props.title;
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row className="forgot-password-form">
                <Col xs={24} lg={10} style={{ height: "100%" }}>
                    <FormContainer>
                        <h5 className="heading">Reset Password?</h5>

                        <hr className="heading-separator" />

                        <p className="sub-heading" style={{ fontSize: 16 }}>Well, let's fix that! Please enter the new password for your account.</p>

                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'This is a required field!' }],
                                })(
                                    <Input type="password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="New Password" />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('cpassword', {
                                    rules: [{ required: true, message: 'This is a required field!' }],
                                })(
                                    <Input type="password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirm Password" />
                                )}
                            </Form.Item>

                            {this.state.error && <Alert className="ant-row ant-form-item" message={this.state.error} type="error" />}
                            {this.state.success && <Alert className="ant-row ant-form-item" message={this.state.success} type="success" />}

                            <Form.Item className="flex-div">
                                <Link to="/login"><Icon type="left-circle" /></Link>
                                <span className="gap"></span>
                                <Button type="primary" htmlType="submit" className="form-button" loading={this.state.loading}>
                                    Update Password
                                </Button>
                            </Form.Item>
                        </Form>
                    </FormContainer>
                </Col>

                <SideColumn />
            </Row>
        );
    }
}

export default Form.create()(ResetPassword);