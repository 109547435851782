import React from 'react';
import { Tabs } from 'antd';
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import NoData from '../../../Dashboard/Partials/NoData';
import { ReactComponent as NoGraph } from '../../../../assets/images/icons/no-graph.svg';
import './index.scss';

class HourWiseStats extends React.Component {

    shouldComponentUpdate() {
        return false;
    }

    render() {
        const { hour_wise_clicks } = this.props;
        const hour_wise_clicks_cols = { hour: { alias: "hour" }, value: { alias: "Clicks:" } };

        const axis1 = <Axis
            name="hour"
            tickLine={{
                lineWidth: 2,
                stroke: '#E9E9E9',
                length: 7,
            }}
            line={{
                stroke: "#E9E9E9",
            }}
        />;
        const axis2 = <Axis
            name="value"
            grid={{
                align: 'bottom',
                type: 'line',
                lineStyle: {
                    stroke: '#E9E9E9',
                    lineWidth: 1,
                    lineDash: [4, 4]
                },
            }}
        />
        const tooltip = <Tooltip
            crosshairs={null}
            containerTpl="<div class=&quot;g2-tooltip&quot;><p class=&quot;g2-tooltip-title&quot;></p><table class=&quot;g2-tooltip-list&quot;></table></div>"
            itemTpl="<tr class=&quot;g2-tooltip-list-item&quot;><td style=&quot;color:{color}&quot;><span class=&quot;g2-tooltip-list-item-dot&quot;></span>{name}</td><td>{value}</td></tr>"
            offset={50}
            g2-tooltip={{
                position: "absolute",
                visibility: "hidden",
                border: "1px solid #000",
                borderRadius: "4px",
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                color: "#fff",
                opacity: "1",
                padding: "1rem 1.25rem",
                transition: "top 200ms,left 200ms",
                fontSize: "14px"
            }}
            g2-tooltip-list={{
                marginTop: "0.5rem"
            }}
        />;
        const placeholder = <NoData
            heading="Not enough data!"
            content="We could not find enough data to build a graph!"
            image={NoGraph}
        />

        return (
            <Tabs defaultActiveKey="2" className="hourwise-stats-tabs">
                <Tabs.TabPane tab="Hourwise Clicks" key="2">
                    <Chart height={300} data={hour_wise_clicks} scale={hour_wise_clicks_cols} forceFit placeholder={placeholder}>
                        {axis1}
                        {axis2}
                        {tooltip}
                        <Geom
                            type="interval"
                            position="hour*value"
                            color="l (270) 0:rgba(54, 198, 38, 1) .5:rgba(54, 198, 38, 1) 1:rgba(54, 198, 38, 1)"

                            style={{
                                shadowColor: "l (270) 0:rgba(21, 146, 255, 0)",
                                shadowBlur: 60,
                                shadowOffsetY: 6,
                            }}
                        />
                    </Chart>
                </Tabs.TabPane>
            </Tabs>
        );
    }
};

export default HourWiseStats;