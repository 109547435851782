import React from 'react';
import { Col } from 'antd';
import './index.scss';

const SideColumn = (props) => {
    return (
        <Col xs={0} lg={14} className="side-img-col">
        </Col>
    );
};

export default SideColumn;