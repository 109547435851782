import React from "react";
import {
    G2,
    Chart,
    Geom,
    Tooltip,
    Coord,
    Guide,
    Legend,
} from "bizcharts";
import './index.scss';
import { Table } from 'antd';
import NoData from "../NoData";

class Pieslice extends React.Component {
    render() {
        const { data, type, category } = this.props;
        const sliceNumber = 0.01;

        G2.Shape.registerShape("interval", "sliceShape", {
            draw(cfg, container) {
                const points = cfg.points;
                let path = [];
                path.push(["M", points[0].x, points[0].y]);
                path.push(["L", points[1].x, points[1].y - sliceNumber]);
                path.push(["L", points[2].x, points[2].y - sliceNumber]);
                path.push(["L", points[3].x, points[3].y]);
                path.push("Z");
                path = this.parsePath(path);
                return container.addShape("path", {
                    attrs: {
                        fill: cfg.color,
                        path: path
                    }
                });
            }
        });

        class SliderChart extends React.Component {
            render() {
                return (
                    <Chart height={400} data={data} padding={[10, 10, 100, 10]} forceFit>
                        <Coord type="theta" innerRadius={0.75} />
                        <Tooltip
                            crosshairs={null}
                            showTitle={false}
                            containerTpl="<div class=&quot;g2-tooltip&quot;><p class=&quot;g2-tooltip-title&quot;></p><table class=&quot;g2-tooltip-list&quot;></table></div>"
                            itemTpl="<tr class=&quot;g2-tooltip-list-item&quot;><td style=&quot;color:{color}&quot;><span class=&quot;g2-tooltip-list-item-dot&quot;></span>{name}</td><td>{value}</td></tr>"
                            offset={50}
                            g2-tooltip={{
                                position: "absolute",
                                visibility: "hidden",
                                border: "1px solid #000",
                                borderRadius: "4px",
                                backgroundColor: "rgba(0, 0, 0, 0.9)",
                                color: "#fff",
                                opacity: "1",
                                padding: "0.25rem 1.25rem 0.75rem 1.25rem",
                                transition: "top 200ms,left 200ms",
                                fontSize: "14px"
                            }}
                            g2-tooltip-list={{
                                marginTop: "0.5rem"
                            }}
                        />
                        <Legend
                            position="bottom"
                            offsetY={0}
                            offsetX={0}
                        />

                        <Guide>
                            <Guide.Html
                                position={["50%", "50%"]}
                                html={`<div style=color:rgba(0,0,0,0.45);font-size:1.21rem;font-weight:400;text-align:center;width:10em;>${
                                    type === 'visitors' ? 'Total Visitors' : 'Total Clicks'
                                    }<br><span style=color:rgba(0,0,0,0.85);font-size:2.14rem>${
                                    data.reduce((sum, object) => sum + Number(object.value), 0)
                                    }</span></div>`}
                                alignX="middle"
                                alignY="middle"
                            />
                        </Guide>

                        <Geom
                            type="intervalStack"
                            position="value"
                            color="type"
                            shape="sliceShape"
                        />
                    </Chart>
                );
            }
        }
        if (data.length === 0) {
            return <NoData heading="Not enough data!" content="We could not find enough data to build a graph!" />
        }
        return (
            <div className="pieslice-container">
                <SliderChart />

                <div className="table">
                    <div className="data-container">
                        <Table columns={
                            [{
                                title: category,
                                dataIndex: 'type',
                                key: 'type',
                                sorter: (a, b) => (a.type > b.type) - (a.type < b.type),
                            }, {
                                title: type,
                                dataIndex: 'value',
                                key: 'value',
                                sorter: (a, b) => a.value - b.value,
                            }]}
                            rowKey={r => r.type}
                            pagination={false}
                            dataSource={data} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Pieslice;