import React, { Component } from "react";
// import { Input, message } from 'antd';
import { Icon } from "antd";
import { connect } from "react-redux";
import { ChromePicker } from "react-color";
// import {ReactComponent as downArrow} from '../../../../assets/images/icons/down-arrow-black.svg';

class ColorPicker extends Component {
  state = {
    displayColorPicker: false,
    color: this.props.defaultValue ? this.props.defaultValue : "#D40000"
  };

  componentDidUpdate(pp) {
    if (pp.defaultValue !== this.props.defaultValue) {
      this.setState({ color: this.props.defaultValue });
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = color => {
    this.setState({ color: color.hex }, () => {
      if (this.props.onChange) {
        let format = "hex";
        if (
          this.props.format &&
          ["hex", "rgb", "hsl"].indexOf(this.props.format) !== -1
        ) {
          format = this.props.format;
        }
        this.props.onChange(color[format]);
      }
    });
  };

  render() {
    const popover = {
      position: "absolute",
      zIndex: "1001"
    };
    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px"
    };
    return (
      <div className="ant-form-item-control">
        {/* <p className="form-labels">{this.props.label}</p>
        {!this.props.hideInput && <Input
          style={{ width: 100 }} value={this.state.color} onClick={this.handleClick} readOnly />} */}
        <div onClick={this.handleClick}>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginLeft: 4,
              marginTop: -3,
              width: 30,
              height: 30,
              borderRadius: 0,
              background: this.state.color,
              border: "1px solid #707070"
            }}
          ></div>
          <Icon
            type="caret-down"
            style={{
              border: "1px solid #707070",
              borderLeft: "none",
              padding: "7px"
            }}
          />
        </div>

        {this.state.displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />
            <ChromePicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};

export default connect(mapStateToProps)(ColorPicker);
