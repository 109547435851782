import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Login from "./Components/Auth/Login/";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import AddUserSignup from "./Components/Auth/AddUserSignup";
import ResetPassword from "./Components/Auth/ResetPassword";
import Signup from "./Components/Auth/Signup";
import Logout from "./Components/Auth/Logout";
import Dashboard from "./Components/Dashboard";
import Exhausted from "./Components/Dashboard/Exhausted";
import Snapshot from "./Components/Dashboard/Snapshot";
import Links from "./Components/Dashboard/Links/Links";
// import Settings from "./Components/Dashboard/Settings";
import NotFound404 from "./Components/NotFound404";
import SwitchToAccount from "./Components/Dashboard/SwitchToAccount";
import ElementReport from "./Components/Dashboard/ElementReport";
import CreateTimer from "./Components/Dashboard/CreateTimer";
import ProfileForm from "./Components/Dashboard/ProfileSettings";
import PixelInstallOptions from "./Components/Dashboard/PixelInstallOptions";
// import Leads from "./Components/Dashboard/Leads";
// import MyBio from "./Components/Dashboard/MyBio";
import Onboarding from "./Components/Onboarding";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="content-body">
          <Switch>
            <Route
              exact
              path="/"
              render={props => <Dashboard {...props} title="Timerly" />}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/"
              render={props => (
                <Dashboard {...props} title="Timerly | Snapshot">
                  <Snapshot {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/login"
              render={props => (
                <Login {...{ ...props, title: "Timerly | Login" }} />
              )}
            />
            <Route
              path="/login/forgot/:hash"
              render={props => (
                <ResetPassword
                  {...{ ...props, title: "Timerly | Reset Password" }}
                />
              )}
            />
            <Route
              path="/login/adduser/:hash"
              render={props => (
                <AddUserSignup {...{ ...props, title: "Timerly | Signup" }} />
              )}
            />
            <Route
              path="/forgot-password"
              render={props => (
                <ForgotPassword
                  {...{ ...props, title: "Timerly | Forgot Password" }}
                />
              )}
            />
            <Route
              path="/signup"
              render={props => (
                <Signup {...{ ...props, title: "Timerly | Signup" }} />
              )}
            />
            <Route
              path="/signup_invite"
              render={props => (
                <Signup {...{ ...props, title: "Timerly | Signup" }} />
              )}
            />
            <Route
              path="/pitchground"
              render={props => (
                <Signup {...{ ...props, title: "Timerly | Signup" }} />
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/pixel"
              render={props => (
                <Dashboard {...props} title="Timerly | Pixel">
                  <PixelInstallOptions {...props} />
                </Dashboard>
              )}
            />
            {/* <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/settings"
              render={props => (
                <Dashboard {...props} title="Timerly | Settings">
                  <Settings {...props} />
                </Dashboard>
              )}
            /> */}

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/timers"
              render={props => (
                <Dashboard {...props} title="Timerly | Timers">
                  <Links {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/create-timer/:elementid([a-fA-F0-9]{24})?"
              render={props => (
                <Dashboard {...props} title="Timerly | Create Timer">
                  <CreateTimer {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/edit-timer/:elementid([a-fA-F0-9]{24})?"
              render={props => (
                <Dashboard {...props} title="Timerly | Edit Timer">
                  <CreateTimer {...props} editTimer={true} />
                </Dashboard>
              )}
            />
            {/* <Route
							exact
							path='/:domid([a-fA-F0-9]{24})/leads'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Leads">
									<Leads {...props} />
								</Dashboard>
							)}
						/>
						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/bio'
							render={(props) => (
								<Dashboard {...props} title="Timerly | My Bio">
									<MyBio {...props}/>
								</Dashboard>
							)}
						/> */}
            {/* <Route
							exact
							path='/:domid([a-fA-F0-9]{24})/settings/accounts'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Settings">
									<Settings {...props} activeTab="1" />
								</Dashboard>
							)}
						/>
						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/settings/users'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Settings">
									<Settings {...props} activeTab="2" />
								</Dashboard>
							)}
						/>
						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/settings/domains'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Settings">
									<Settings {...props} activeTab="3" />
								</Dashboard>
							)}
						/> */}

            {/* <Route
							exact
							path='/:domid([a-fA-F0-9]{24})/elements'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Elements" openKeys={["submenu_elements"]}>
									<Elements {...props} />
								</Dashboard>
							)}
						/> */}

            {/* <Route
							exact
							path='/:domid([a-fA-F0-9]{24})/elements/archived'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Archived Elements" openKeys={["submenu_elements"]}>
									<Elements {...props} archived="1" />
								</Dashboard>
							)}
						/> */}

            {/* <Route
							exact
							path='/:domid([a-fA-F0-9]{24})/link_cloaker'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Link Cloaker" openKeys={["submenu_links"]}>
									<LinkCloaker {...props} />
								</Dashboard>
							)}
						/> */}

            {/* <Route
							exact
							path='/:domid([a-fA-F0-9]{24})/link_details/:linkid([a-fA-F0-9]{24})'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Link Details">
									<LinkReport {...props} />
								</Dashboard>
							)}
							/> */}

            <Route
              exact
              path="/onboarding"
              render={props => (
                <Onboarding title="Timerly | Onboarding" {...props} />
              )}
            />

            <Route
              exact
              path="/settings"
              render={props => (
                <Dashboard {...props} title="Timerly | Profile Settings">
                  <ProfileForm {...props} />
                </Dashboard>
              )}
            />

            {/*<Route
							exact
							path='/complete_profile'
							render={(props) => (
								<SignupQuestions title="Timerly | Complete Profile" {...props} />
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/library/youzign'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Youzign" openKeys={["submenu_library"]}>
									<Youzign {...props} />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/library/search-gifs'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Search GIFs" openKeys={["submenu_library"]}>
									<SearchMedia {...props} type="gifs" />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/library/pixabay'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Pixabay" openKeys={["submenu_library"]}>
									<SearchMedia {...props} type="pixabay" />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/library'
							render={(props) => (
								<Dashboard {...props} title="Timerly | My Library" openKeys={["submenu_library"]}>
									<Library {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/new_element/:elementid([a-fA-F0-9]{24})?'
							render={(props) => (
								<Dashboard {...props} title="Timerly | New Element" openKeys={["submenu_elements"]}>
									<NewElement {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/bonus'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Bonus">
									<BonusPage {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/manage-accounts'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Manage Accounts">
									<ManageAccountsMain {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/activation-codes'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Activation Codes">
									<ActivationCodes {...props} />
								</Dashboard>
							)}
						/> */}

            {/* <Route
							exact
							path='/billing'
							render={(props) => (
								<Dashboard {...props} title="Timerly | Billing">
									<Billing {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/steps/:stepid([0-9])?'
							render={(props) => (
								<Steps title="Timerly | Onboarding" {...props} />
							)}
						/> */}

            <Route
              path="/switch-account/:domid([a-fA-F0-9]{24})?"
              render={props => <SwitchToAccount {...props} />}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/analytics/:campid([a-fA-F0-9]{24})"
              render={props => (
                <Dashboard {...props} title="Timerly | Element Details">
                  <ElementReport {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/logout"
              render={props => <Logout {...props} />}
            />

            <Route
              exact
              path="/exhausted"
              render={props => (
                <Dashboard {...props} title="Timerly">
                  <Exhausted {...props} />
                </Dashboard>
              )}
            />

            <Route path="*" render={() => <NotFound404 />} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
