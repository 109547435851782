import React, { Component } from "react";
import "./index.scss";
import "ant-design-pro/dist/ant-design-pro.css";
import OverallStats from "./OverallStats";
import Last30DayStats from "./Last30DayStats";
import { getElements } from "../../../helpers/ajax/elementsAjax";
import { connect } from "react-redux";
import { Skeleton, Alert, Row, Col, Card, Modal, Icon, Button } from "antd";
import NoData from "../Partials/NoData";
import { ReactComponent as NoGraph } from "../../../assets/images/icons/no-graph.svg";
import MainHeadingBar from "../Partials/MainHeadingBar";
import TimerCard from "../Links/TimerCard";
import queryString from "query-string";

class Snapshot extends Component {
  state = {
    activation_status: 0,
    product_updates_available: 1,
    loading: false,
    error: false,
    elements: [],
    errorMsg: ""
  };

  fetchRecentElements() {
    getElements(this.props.match.params.domid, 0, 1, 4).then(res => {
      this.setState({
        elements: res.data.elements
      });
    });
  }

  componentDidMount() {
    this.fetchRecentElements();
  }

  render() {
    const { loading, error, errorMsg } = this.state;

    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Card>
            <Skeleton active />
          </Card>
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    const parsed = queryString.parse(window.location.search);
    return (
      <div className="snapshot">
        <MainHeadingBar title="Your Summary" {...this.props} />
        <OverallStats {...this.props} />
        {/* <NextSteps /> */}
        <Last30DayStats {...this.props} />

        <Row
          className="elements-container"
          gutter={30}
          style={{ margin: "20px 0" }}
        >
          <h3>Recent Timers</h3>
          {this.state.elements.length > 0 ? (
            this.state.elements.map(timer => (
              <Col md={12}>
                <TimerCard {...this.props} timer={timer} />
              </Col>
            ))
          ) : (
            <NoData
              heading="Not enough data!"
              content="We could not any timers in your account!"
              image={NoGraph}
            />
          )}
        </Row>

        <Modal
          visible={parsed["showWelcome"] === "true"}
          footer={null}
          closable={false}
          width={536}
          style={{ textAlign: "center" }}
        >
          <Icon
            type="check-circle"
            theme="filled"
            style={{ color: "#00a154", fontSize: 60 }}
          />

          <h3 className="heading" style={{ fontSize: 30 }}>
            Success!
          </h3>

          <p className="disclaimer">
            Your account setup is complete. We have allocated 1,000 monthly
            impressions to your account. To manage your monthly impressions you
            can visit the Manage Accounts Page.
          </p>
          <Button
            style={{
              background: "#00a154",
              borderColor: "#00a154",
              color: "#fff"
            }}
            onClick={() => this.props.history.push("/manage-accounts")}
          >
            Manage Accounts
          </Button>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};

export default connect(mapStateToProps)(Snapshot);
