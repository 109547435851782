const getStateFromLocalStorage = () => {
	let state = {
		authError: null,
		auth: {
			logged_in: false,
			user: { user_fname: '', user_domains: {}, user_questions: 0 },
			domain: {},
			plan: { plan_name: [], plan_features: {} },
			loginplan: { plan_name: [], plan_features: {} },
		}
	};
	if (localStorage.getItem("logged_in")) {
		state.auth.logged_in = localStorage.getItem("logged_in");
	}
	if (localStorage.getItem('user')) {
		try {
			state.auth.user = JSON.parse(localStorage.getItem('user'));
		} catch (e) { }
	}
	if (localStorage.getItem('domain')) {
		try {
			state.auth.domain = JSON.parse(localStorage.getItem('domain'));
		} catch (e) { }
	}
	if (localStorage.getItem('plan')) {
		try {
			state.auth.plan = JSON.parse(localStorage.getItem('plan'));
		} catch (e) { }
	}
	if (localStorage.getItem('loginplan')) {
		try {
			state.auth.loginplan = JSON.parse(localStorage.getItem('loginplan'));
		} catch (e) { }
	}
	return state;
}

const initState = getStateFromLocalStorage();

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case 'LOGIN_ERROR':
			return {
				...state,
				authError: action.err
			}

		case 'LOGIN_SUCCESS':
			localStorage.setItem('logged_in', true);
			localStorage.setItem('user', JSON.stringify(action.payload.data.user));
			localStorage.setItem('domain', JSON.stringify(action.payload.data.domain));
			localStorage.setItem('plan', JSON.stringify(action.payload.data.plan));
			localStorage.setItem('loginplan', JSON.stringify(action.payload.data.loginplan));
			return {
				...state,
				auth: {
					logged_in: true,
					user: action.payload.data.user,
					domain: action.payload.data.domain,
					plan: action.payload.data.plan,
				},
				authError: null
			}

		case 'ACCOUNT_SWITCH_SUCCESSFUL':
			localStorage.setItem('logged_in', true);
			localStorage.setItem('user', JSON.stringify(action.payload.data.user));
			localStorage.setItem('domain', JSON.stringify(action.payload.data.domain));
			localStorage.setItem('plan', JSON.stringify(action.payload.data.plan));
			localStorage.setItem('loginplan', JSON.stringify(action.payload.data.loginplan));
			return {
				...state,
				auth: {
					logged_in: true,
					user: action.payload.data.user,
					domain: action.payload.data.domain,
					plan: action.payload.data.plan,
					loginplan: action.payload.data.loginplan,
				},
				authError: null
			}

		case 'SIGNOUT_SUCCESS':
			localStorage.clear();
			return {
				...state,
				auth: {
					logged_in: false,
					user: { user_fname: '', user_domains: {} },
					domain: {},
					plan: { plan_name: [], plan_features: {} },
					loginplan: { plan_name: [], plan_features: {} }
				},
				authError: null
			}

		case 'UPDATE_PLAN':
			localStorage.setItem('plan', JSON.stringify(action.payload.plan));
			localStorage.setItem('loginplan', JSON.stringify(action.payload.loginplan));
			return {
				...state,
				auth: {
					...state.auth,
					plan: action.payload.plan,
					loginplan: action.payload.loginplan
				}
			}

		default:
			return state
	}
};

export default authReducer;