import React, { Component } from "react";
import { Icon, Input, Select } from "antd";

const { Option } = Select;

export default class TriggerTemplate extends Component {
  render() {
    const data = this.props.data;
    return (
      <div style={{ marginTop: "20px" }}>
        <p>
          {this.props.title} (
          <button onClick={() => this.props.addTrigger(this.props.title)}>
            Add
          </button>
          ){" "}
        </p>
        {data.map((element, i) => (
          <div className="evergreen" key={element.id}>
            <div className="inner-timer">
              <Select
                value={element.type}
                onChange={value =>
                  this.props.setValue(
                    `element.${this.props.type}.${i}.type`,
                    value
                  )
                }
              >
                <Option value={"CONTAINS"}>Contains</Option>
                <Option value={"EXACT"}>Exact Match</Option>
                <Option value={"REGEX"}>REGEX</Option>
              </Select>
            </div>

            <div style={{ flex: ".1", textAlign: "center" }} />
            <div className="inner-timer">
              <Input
                onChange={e =>
                  this.props.setValue(
                    `element.${this.props.type}.${i}.data`,
                    e.target.value
                  )
                }
                value={element.data}
              />
            </div>

            <div style={{ flex: ".2", textAlign: "center" }}>
              <button
                onClick={() =>
                  this.props.deleteTrigger(element.id, this.props.title)
                }
              >
                <Icon type="cross" />
              </button>
            </div>
          </div>
        ))}
        <span className="spanClass" style={{ color: "red" }}>
          {this.props.error}
        </span>
      </div>
    );
  }
}
