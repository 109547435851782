import React from "react";
import "./index.scss";
import { Row, Col, Button, Tooltip, Icon } from "antd";

const MainHeadingBar = props => {
  let cta = (
    <div className="actions">
      <Button
        size="large"
        onClick={() =>
          props.history.push(`/${props.match.params.domid}/create-timer`)
        }
      >
        Create New Timer
      </Button>
    </div>
  );
  if (props.cta) {
    cta = props.cta;
  }
  return (
    <div className="main-heading-bar">
      <Row>
        <Col xs={24} md={12}>
          <h3 className="title">
            {props.title}{" "}
            {props.helpText && (
              <Tooltip title={props.helpText}>
                <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
              </Tooltip>
            )}
          </h3>
          <div className="page-cta">
            {props.subtitle && <p className="subtitle">{props.subtitle}</p>}{" "}
            {props.pageCTA !== undefined && props.pageCTA}
          </div>
        </Col>
        {!props.hideCTA && cta}
      </Row>
    </div>
  );
};

export default MainHeadingBar;
