import React, { Component } from "react";
import "./index.scss";
import TriggerTemplate from "./TriggerTemplate";
import { Button } from "antd";

export default class Trigger extends Component {
  render() {
    return (
      <div className="trigger">
        <div className="main-header">
          <h1>TRIGGERS</h1>
          <p>
            CONFIGURE WHERE TO SHOW UP THE CAMPAIGN AND OTHER CONVERSION
            SPECIFIC SETTINGS.
          </p>
          <span>(ALL FIELDS MARKED WITH A * ARE REQUIRED)</span>
        </div>
        <div className="trigger-form">
          <div style={{ marginTop: "20px" }}>
            <p>Display URL Settings * </p>
          </div>
          <TriggerTemplate
            title={"Include"}
            setValue={this.props.setValue}
            data={this.props.data.element_displayurl}
            addTrigger={this.props.addTrigger}
            deleteTrigger={this.props.deleteTrigger}
            error={this.props.errors.element_displayurl}
            type={"element_displayurl"}
          />
          <TriggerTemplate
            title={"Exclude"}
            setValue={this.props.setValue}
            data={this.props.data.element_excludeurl}
            addTrigger={this.props.addTrigger}
            deleteTrigger={this.props.deleteTrigger}
            error={this.props.errors.element_excludeurl}
            type={"element_excludeurl"}
          />
          <TriggerTemplate
            title={"Referrer"}
            setValue={this.props.setValue}
            data={this.props.data.element_referrer}
            addTrigger={this.props.addTrigger}
            deleteTrigger={this.props.deleteTrigger}
            error={this.props.errors.element_referrer}
            type={"element_referrer"}
          />
        </div>
        <div>
          <Button
            className="btn-green"
            onClick={() => this.props.saveData()}
            loading={this.props.saving}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}
