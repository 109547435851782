import React, { Component } from "react";
import "./index.scss";
import {
  Input,
  Select,
  Radio,
  InputNumber,
  Switch,
  Button,
  DatePicker
} from "antd";
import moment from "moment";
import timezones from "../../../../helpers/timezones";
import Customisation from "./Customisation";
import FormattingField from "../../Partials/FormattingField";

const { Option } = Select;

export default class GeneralSetting extends Component {
  state = {
    advancedCustomize: false
  };

  onChange = checked => {
    this.setState({
      advancedCustomize: checked
    });
  };
  render() {
    const data = this.props.data;
    return (
      <div className="general-setting">
        <div className="main-header">
          <h1>TIMER DETAILS</h1>
          <p>THE DETAILS YOU WOULD LIKE IN THE TIMER</p>
          <span>(ALL FIELDS MARKED WITH A * ARE REQUIRED)</span>
        </div>
        <div className="general-form">
          <div style={{ marginTop: "20px" }}>
            <p>Timer Name *</p>
            <Input
              onChange={e =>
                this.props.setValue("element.element_name", e.target.value)
              }
              placeholder="Timer Name"
              value={data.element_name}
            />
            <span className="spanclass">{this.props.errors.element_name}</span>
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>Timer Template *</p>
            <Select
              value={data.element_data.timerTemplate}
              onChange={value =>
                this.props.setValue("element.element_data.timerTemplate", value)
              }
            >
              <Option value="1">Template 1</Option>
              <Option value="2">Template 2</Option>
              <Option value="3">Template 3</Option>
            </Select>
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>Timer Type * </p>
            <Radio.Group
              onChange={e =>
                this.props.setValue(
                  "element.element_data.timerType",
                  e.target.value
                )
              }
              value={data.element_data.timerType}
            >
              <Radio value={"date"}> Date and Time Based</Radio>
              <Radio value={"cookie"}> Cookie Based</Radio>
              <Radio value={"evergreen"}> Evergreen</Radio>
            </Radio.Group>
            {data.element_data.timerType === "evergreen" ||
            data.element_data.timerType === "cookie" ? (
              <div style={{ marginTop: "20px" }}>
                <p>Timer Duration * </p>
                <div className="evergreen">
                  <div className="inner-timer">
                    <InputNumber
                      min={0}
                      onChange={value =>
                        this.props.setValue("element.element_data.days", value)
                      }
                      value={data.element_data.days}
                    />
                    <p>Days</p>
                    <span className="spanclass">{this.props.errors.days}</span>
                  </div>
                  <div className="inner-timer">
                    <InputNumber
                      min={0}
                      max={23}
                      onChange={value =>
                        this.props.setValue("element.element_data.hours", value)
                      }
                      value={data.element_data.hours}
                    />
                    <p>Hours</p>
                    <span className="spanclass">{this.props.errors.hours}</span>
                  </div>
                  <div className="inner-timer">
                    <InputNumber
                      min={0}
                      max={59}
                      onChange={value =>
                        this.props.setValue(
                          "element.element_data.minutes",
                          value
                        )
                      }
                      value={data.element_data.minutes}
                    />
                    <p>Minutes</p>
                    <span className="spanclass">
                      {this.props.errors.minutes}
                    </span>
                  </div>
                  <div className="inner-timer">
                    <InputNumber
                      min={0}
                      max={59}
                      onChange={value =>
                        this.props.setValue(
                          "element.element_data.seconds",
                          value
                        )
                      }
                      value={data.element_data.seconds}
                    />
                    <p>Seconds</p>
                    <span className="spanclass">
                      {this.props.errors.seconds}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {data.element_data.timerType === "date" ? (
              <div>
                <div style={{ marginTop: "20px" }}>
                  <p>End Date and time *</p>
                  <DatePicker
                    showTime
                    placeholder="Select Time"
                    onChange={(_, dateString) =>
                      this.props.setValue(
                        "element.element_data.endDateTime",
                        dateString
                      )
                    }
                    value={
                      data.element_data.endDateTime
                        ? moment(data.element_data.endDateTime)
                        : null
                    }
                  />
                  <span className="spanclass">
                    {this.props.errors.endDateTime}
                  </span>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <p>Timezone*</p>
                  <Select
                    value={data.element_data.timezone}
                    onChange={value =>
                      this.props.setValue(
                        "element.element_data.timezone",
                        value
                      )
                    }
                  >
                    {Object.keys(timezones).map(t => (
                      <Option key={t} value={`${t}|${timezones[t]}`}>
                        {`${t}|${timezones[t]}`}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <FormattingField
            label={<span>Timer Title</span>}
            field={props => <Input placeholder="" {...props} />}
            personalizations={[
              { tag: "[FNAME]", title: "First Name" },
              { tag: "[NAME]", title: "Full Name" }
            ]}
            error={this.props.errors.title}
            value={data.element_data.title}
            onChange={val =>
              this.props.setValue("element.element_data.title", val)
            }
          />

          <div style={{ marginTop: "20px" }}>
            <p>Query Variable to pick personalization tag value from </p>
            <Input
              onChange={e =>
                this.props.setValue(
                  "element.element_data.queryVar",
                  e.target.value
                )
              }
              placeholder="Query Variable"
              value={data.element_data.queryVar}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>Fallback text to personalization tag</p>
            <Input
              onChange={e =>
                this.props.setValue(
                  "element.element_data.fallback",
                  e.target.value
                )
              }
              placeholder="Fallback Text"
              value={data.element_data.fallback}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <p>Timer Labels *</p>
            <div className="evergreen">
              <div className="inner-timer1" style={{ margin: "0 10px" }}>
                <Input
                  onChange={e =>
                    this.props.setValue(
                      "element.element_data.labels.days",
                      e.target.value
                    )
                  }
                  placeholder="Days"
                  value={data.element_data.labels.days}
                />
                <p>Days</p>
                <span className="spanclass">{this.props.errors.labelDays}</span>
              </div>
              <div className="inner-timer1" style={{ margin: "0 10px" }}>
                <Input
                  onChange={e =>
                    this.props.setValue(
                      "element.element_data.labels.hours",
                      e.target.value
                    )
                  }
                  placeholder="Hours"
                  value={data.element_data.labels.hours}
                />
                <p>Hours</p>
                <span className="spanclass">
                  {this.props.errors.labelHours}
                </span>
              </div>
              <div className="inner-timer1" style={{ margin: "0 10px" }}>
                <Input
                  onChange={e =>
                    this.props.setValue(
                      "element.element_data.labels.minutes",
                      e.target.value
                    )
                  }
                  placeholder="Minutes"
                  value={data.element_data.labels.minutes}
                />
                <p>Minutes</p>
                <span className="spanclass">
                  {this.props.errors.labelMinutes}
                </span>
              </div>
              <div className="inner-timer1" style={{ margin: "0 10px" }}>
                <Input
                  onChange={e =>
                    this.props.setValue(
                      "element.element_data.labels.seconds",
                      e.target.value
                    )
                  }
                  placeholder="Seconds"
                  value={data.element_data.labels.seconds}
                />
                <p>Seconds</p>
                <span className="spanclass">
                  {this.props.errors.labelSeconds}
                </span>
              </div>
            </div>
          </div>

          {data.element_data.timerType !== "evergreen" ? (
            <div style={{ marginTop: "20px" }}>
              <p>When Timer Expires * </p>
              <Select
                value={data.element_data.postExpiryAction}
                onChange={value =>
                  this.props.setValue(
                    "element.element_data.postExpiryAction",
                    value
                  )
                }
              >
                <Option value="hide">Hide Timer</Option>
                <Option value="redirect">Redirect The Page</Option>
                <Option value="static">Show Static Timer As 00:00</Option>
              </Select>
            </div>
          ) : (
            ""
          )}
          {data.element_data.postExpiryAction === "redirect" ? (
            <div style={{ marginTop: "20px" }}>
              <p>Post-expiry Redirect URL * </p>
              <Input
                placeholder="http://letx.co"
                value={data.element_data.postExpiryRedirectUrl}
                onChange={e =>
                  this.props.setValue(
                    "element.element_data.postExpiryRedirectUrl",
                    e.target.value
                  )
                }
              />
              <span className="spanclass">
                {this.props.errors.postExpiryRedirectUrl}
              </span>
            </div>
          ) : (
            ""
          )}

          <div style={{ marginTop: "20px", display: "flex" }}>
            <Switch
              checked={this.state.advancedCustomize}
              onChange={checked => this.onChange(checked)}
            />
            <p style={{ marginLeft: "10px" }}> Styling Customisation </p>
          </div>

          {this.state.advancedCustomize ? (
            <Customisation data={data} setValue={this.props.setValue} />
          ) : (
            ""
          )}
        </div>
        <div style={{ marginTop: "20px", display: "flex" }}>
          <Button onClick={() => this.props.saveGeneralSettings()}>Next</Button>
        </div>
      </div>
    );
  }
}
