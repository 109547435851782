import React, { Component } from 'react';
import './index.scss';
import { Form, Icon, Input, Button, Card, Checkbox, message, Popover, Alert } from 'antd';
import { ReactComponent as Logo } from '../../../assets/images/logos/logo.svg';
import OnboardingFooter from '../../Dashboard/Partials/OnboardingFooter';
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check-circle-filled.svg';
import { ReactComponent as CrossIcon } from '../../../assets/images/icons/cross-circle-filled.svg';
import { connect } from 'react-redux';
import axios from 'axios';

class AddUserSignup extends Component {
	state = {
		loading: true,
		invalid_link: false,
		link_validation_error: false,
	};

	handleSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFields((err, values) => {
			if (!err) {
				axios({
					url: '/login/subuser_signup',
					method: 'post',
					data: {
						...values,
						hash: this.props.match.params.hash
					}
				}).then((res) => {
					if (res.data.success === 1) {
						this.props.history.push('/switch-account');
					} else {
						message.error(res.data.msg);
					}
				}).catch((e) => {
					message.error(e.message);
				});
			}
		});
	}

	componentDidMount() {
		document.title = this.props.title;

		axios({
			url: '/login/get_subuser',
			method: 'post',
			data: {
				hash: this.props.match.params.hash
			}
		}).then((res) => {
			if (res.data.success === 1) {
				this.setState({
					loading: false,
				});
				this.props.form.setFieldsValue({
					...res.data.user
				});

			} else {
				this.setState({
					loading: false,
					invalid_link: true,
					link_validation_error: false
				})
			}
		}).catch(() => {
			this.setState({
				loading: false,
				link_validation_error: true,
				invalid_link: false
			})
		})
	}

	componentWillReceiveProps(props) {
		document.title = props.title;
	}

	validatePassword = (password) => {
		let hasUppercaseLetter = false, hasLowerCaseLetter = false, hasNumber = false;

		this.setState({
			...this.state,
			atLeastEightCharacters: typeof (password) === "string" && password.length >= 8,
			hasUppercaseLetter,
			hasLowerCaseLetter,
			hasNumber
		}, () => {
			for (let c of password) {
				if (c !== ' ' && !c.match(/^[^a-zA-Z0-9]+$/)) {
					if (isNaN(Number(c))) {
						if (c.toUpperCase() === c) {
							hasUppercaseLetter = true;
						}
						if (c.toLowerCase() === c) {
							hasLowerCaseLetter = true;
						}
					} else {
						hasNumber = true;
					}
				}
			}

			this.setState({
				...this.state,
				hasUppercaseLetter,
				hasLowerCaseLetter,
				hasNumber
			});
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { atLeastEightCharacters, hasUppercaseLetter, hasLowerCaseLetter, hasNumber } = this.state;
		const { logged_in, domain } = this.props.auth;

		const popoverContent = <div className="signup-password-popover-content">
			<h5>Your password must contain:</h5>
			<p>{atLeastEightCharacters ? <CheckIcon /> : <CrossIcon />} at least 8 characters</p>
			<p>{hasUppercaseLetter ? <CheckIcon /> : <CrossIcon />} an uppercase letter </p>
			<p>{hasLowerCaseLetter ? <CheckIcon /> : <CrossIcon />} a lowercase letter</p>
			<p>{hasNumber ? <CheckIcon /> : <CrossIcon />} a number</p>
		</div>;

		if (logged_in) this.props.history.push(`/${domain.id}`);

		return (
			<div className="signup-form-container">
				<Logo className="logo" />

				<Card className="signup-card" loading={this.state.loading}>

					{!this.state.loading && this.state.link_validation_error &&
						<Alert
							style={{ marginTop: 30 }}
							message="Error"
							description="There was an error. Please try refreshing the page."
							type="error"
							showIcon
						/>
					}

					{!this.state.loading && this.state.invalid_link &&
						<Alert
							style={{ marginTop: 30 }}
							message="Error"
							description="You seem to have followed an invalid link"
							type="error"
							showIcon
						/>
					}

					{!this.state.loading && !this.state.link_validation_error && !this.state.invalid_link &&
						<React.Fragment>
							<h2 className="title">Let's set up your account.</h2>
							<Form onSubmit={this.handleSubmit} className="signup-form">
								<div className="flex">
									<Form.Item>
										<span className="label">First Name</span>
										{getFieldDecorator('first_name', {
											rules: [{ required: true, message: 'Please input your first name!' }],
										})(
											<Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Your First Name" />
										)}
									</Form.Item>

									<Form.Item>
										<span className="label">Last Name</span>
										{getFieldDecorator('last_name', {
											rules: [{ required: true, message: 'Please input your last name!' }],
										})(
											<Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Your Last Name" />
										)}
									</Form.Item>
								</div>

								<Form.Item>
									<span className="label">Email Address</span>
									{getFieldDecorator('email', {
										rules: [
											{ required: true, message: 'Please input your email!' },
											{ type: 'email', message: 'Please enter a valid email address.' }
										],
									})(
										<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Your Email Id" disabled />
									)}
								</Form.Item>

								<Form.Item>
									<span className="label">Set Up A Password</span>

									<Popover placement="right" content={popoverContent} trigger="focus" >
										{getFieldDecorator('password', {
											rules: [{ required: true, pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,}/, message: 'Please enter a valid password!' }],
										})(
											<Input.Password onChange={e => this.validatePassword(e.target.value)} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Your Password" />
										)}
									</Popover>
								</Form.Item>

								<Form.Item>
									{getFieldDecorator('user_receive_updates', {
										rules: [],
										valuePropName: 'checked',
										initialValue: true,
									})(
										<Checkbox>I'd like to receive product updates, offers and tips via email</Checkbox>
									)}
									<span className="label checkbox-label">
										You may opt out at any time using the Unsubscribe link provided in your emails.
                            </span>
								</Form.Item>

								<div className="flex">
									<p className="label disclaimer">
										By continuing, I acknowledge that I've read and I agree to the <a href="https://letx.co/terms">Terms of Service</a>, <a href="https://letx.co/privacy-policy">Privacy Policy</a> and <a href="https://letx.co/dpa">Data Protection Addendum</a>.
                            </p>

									<Button type="primary" htmlType="submit" className="form-button">
										Continue
                            </Button>
								</div>
							</Form>
						</React.Fragment>
					}
				</Card>

				<OnboardingFooter />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth.auth
	};
};

export default connect(mapStateToProps)(Form.create()(AddUserSignup));