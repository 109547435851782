import React, { Component } from "react";
import ColorPicker from "../../ColorPicker";
import { InputNumber, Select } from "antd";

const { Option } = Select;

export default class Customisation extends Component {
  render() {
    const data = this.props.data;
    return (
      <div>
        <div style={{ marginTop: "20px" }}>
          <p>Timer Styling </p>
          <div className="evergreen">
            <div className="inner-timer">
              <InputNumber
                onChange={value =>
                  this.props.setValue(
                    "element.element_data.timerFontSize",
                    value
                  )
                }
                value={data.element_data.timerFontSize}
              />
              <p>Font Size</p>
            </div>
            <div style={{ flex: ".5", textAlign: "center" }}>
              <ColorPicker
                defaultValue={data.element_data.timerColor}
                onChange={color =>
                  this.props.setValue("element.element_data.timerColor", color)
                }
              />
              <p>Color</p>
            </div>
            <div className="inner-timer">
              <Select
                value={data.element_data.timerFont}
                onChange={value =>
                  this.props.setValue("element.element_data.timerFont", value)
                }
              >
                <Option value="Quicksand">Quicksand</Option>
                <Option value="poppins">Poppins</Option>
                <Option value="roboto">Roboto</Option>
              </Select>
              <p>Font</p>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <p>Timer Title Styling </p>
          <div className="evergreen">
            <div className="inner-timer">
              <InputNumber
                onChange={value =>
                  this.props.setValue(
                    "element.element_data.titleFontSize",
                    value
                  )
                }
                value={data.element_data.titleFontSize}
              />
              <p>Font Size</p>
            </div>
            <div style={{ flex: ".5", textAlign: "center" }}>
              <ColorPicker
                defaultValue={data.element_data.titleColor}
                onChange={color =>
                  this.props.setValue("element.element_data.titleColor", color)
                }
              />
              <p>Color</p>
            </div>
            <div className="inner-timer">
              <Select
                value={data.element_data.titleFont}
                onChange={value =>
                  this.props.setValue("element.element_data.titleFont", value)
                }
              >
                <Option value="Quicksand">Quicksand</Option>
                <Option value="poppins">Poppins</Option>
                <Option value="roboto">Roboto</Option>
              </Select>
              <p>Font</p>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <p>Timer Label Styling </p>
          <div className="evergreen">
            <div className="inner-timer">
              <InputNumber
                onChange={value =>
                  this.props.setValue(
                    "element.element_data.labelFontSize",
                    value
                  )
                }
                value={data.element_data.labelFontSize}
              />
              <p>Font Size</p>
            </div>
            <div style={{ flex: ".5", textAlign: "center" }}>
              <ColorPicker
                defaultValue={data.element_data.labelColor}
                onChange={color =>
                  this.props.setValue("element.element_data.labelColor", color)
                }
              />
              <p>Color</p>
            </div>
            <div className="inner-timer">
              <Select
                value={data.element_data.labelFont}
                onChange={value =>
                  this.props.setValue("element.element_data.labelFont", value)
                }
              >
                <Option value="Quicksand">Quicksand</Option>
                <Option value="poppins">Poppins</Option>
                <Option value="roboto">Roboto</Option>
              </Select>
              <p>Font</p>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <p>Highlight Styling </p>
          <div className="evergreen">
            <div className="inner-timer">
              <InputNumber
                onChange={value =>
                  this.props.setValue(
                    "element.element_data.highlightFontSize",
                    value
                  )
                }
                value={data.element_data.highlightFontSize}
              />
              <p>Font Size</p>
            </div>
            <div style={{ flex: ".5", textAlign: "center" }}>
              <ColorPicker
                defaultValue={data.element_data.highlightColor}
                onChange={color =>
                  this.props.setValue(
                    "element.element_data.highlightColor",
                    color
                  )
                }
              />
              <p>Color</p>
            </div>
            <div className="inner-timer">
              <Select
                value={data.element_data.highlightFont}
                onChange={value =>
                  this.props.setValue(
                    "element.element_data.highlightFont",
                    value
                  )
                }
              >
                <Option value="Quicksand">Quicksand</Option>
                <Option value="poppins">Poppins</Option>
                <Option value="roboto">Roboto</Option>
              </Select>
              <p>Font</p>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px", display: "flex" }}>
          <p style={{ marginRight: "10px" }}> Highlight Background Color</p>
          <ColorPicker
            defaultValue={data.element_data.highlightBgColor}
            onChange={color =>
              this.props.setValue(
                "element.element_data.highlightBgColor",
                color
              )
            }
          />
        </div>
      </div>
    );
  }
}
