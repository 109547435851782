import React from "react";
import { ReactComponent as ViewsIcon } from "../../../assets/images/icons/overall-view.svg";
import { Card, Icon } from "antd";
import { Link } from "react-router-dom";
import ClickToCopy from "../Partials/ClickToCopy";
import "./TimerCard.scss";

export default props => {
  const timer = props.timer;
  const timerTypes = {
    date: "Date & Time",
    cookie: "Cookie Based",
    evergreen: "Evergreen"
  };
  let views = 0;
  if (
    timer.element_analytics &&
    timer.element_analytics.overall &&
    timer.element_analytics.overall.views
  ) {
    views = timer.element_analytics.overall.views;
  }
  return (
    <Card className="timer-card" style={{ margin: "0 0 30px 0" }}>
      <div className="timer-info" style={{ flex: 1 }}>
        <h3>Timer Name:</h3>
        <p>{timer.element_name}</p>
      </div>
      <div className="timer-info" style={{ flex: 2 }}>
        <h3>Timer Type:</h3>
        <p>{timerTypes[timer.element_data.timerType]}</p>
      </div>
      <div className="timer-info" style={{ flex: 1 }}>
        <h3>Expiry:</h3>
        <p>
          {timer.element_data.timerType === "date"
            ? `${timer.element_data.endDateTime} ${
                timer.element_data.timezone.split("|")[0]
              }`
            : `
            ${
              Number(timer.element_data.days) === 0
                ? ``
                : `${timer.element_data.days}d`
            }
            ${
              Number(timer.element_data.hours) === 0
                ? ``
                : `${timer.element_data.hours}h`
            }
            ${
              Number(timer.element_data.minutes) === 0
                ? ``
                : `${timer.element_data.minutes}m`
            }
            ${
              Number(timer.element_data.seconds) === 0
                ? ``
                : `${timer.element_data.seconds}s`
            }`}
        </p>
      </div>
      <div className="timer-actions">
        <ul>
          <li>
            {views} <Icon component={ViewsIcon} />
          </li>
          <li>
            <ClickToCopy
              textToCopy={`<div class="timerly_clock_container_${
                timer._id["$id"]
              }"></div>`}
            >
              <a href="#!">
                <Icon type="copy" /> Copy Embed Code
              </a>
            </ClickToCopy>
          </li>
          <li>
            <Link
              to={`/${props.match.params.domid}/edit-timer/${timer._id["$id"]}`}
            >
              <Icon type="edit" /> Edit
            </Link>
          </li>
          <li>
            <Link
              to={`/${props.match.params.domid}/create-timer/${
                timer._id["$id"]
              }`}
            >
              <Icon type="copy" />
              Clone
            </Link>
          </li>
          {!props.hideReport && (
            <li>
              <Link
                to={`/${props.match.params.domid}/analytics/${
                  timer._id["$id"]
                }`}
              >
                <Icon type="pie-chart" /> Report
              </Link>
            </li>
          )}
        </ul>
      </div>
    </Card>
  );
};
