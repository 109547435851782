import React, { Component } from "react";
import "./index.scss";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { Card, Tabs, message, Modal, Icon, Button, Skeleton } from "antd";
import axios from "axios";
import GeneralSetting from "./GeneralSetting";
import { set } from "lodash";
import Trigger from "./Trigger";
import ClickToCopy from "../Partials/ClickToCopy";
import { newElement } from "../../../helpers/ajax/elementsAjax";
import "./index.scss";

const { TabPane } = Tabs;

export default class CreateTimer extends Component {
  state = {
    errors: {},
    activeTab: "1",
    element: {
      element_name: "",
      element_displayurl: [
        {
          id: Date.now(),
          type: "CONTAINS",
          data: ""
        }
      ],
      element_excludeurl: [],
      element_referrer: [],
      element_data: {
        timerTemplate: "template1",

        timerType: "date",
        endDateTime: "",
        timezone: "(UTC-11:00) Midway Island|Pacific/Midway",
        days: "0",
        hours: "0",
        minutes: "0",
        seconds: "0",

        title: "",
        labels: {
          days: "Days",
          hours: "Hours",
          minutes: "Minutes",
          seconds: "Seconds"
        },

        postExpiryAction: "hide", // hide | redirect | static
        postExpiryRedirectUrl: "",

        timerFontSize: "20",
        timerColor: "#ff0000",
        timerFont: "Quicksand",
        titleFontSize: "20",
        titleColor: "#e80909",
        titleFont: "Quicksand",
        labelFontSize: "10",
        labelColor: "#ee1414",
        labelFont: "Quicksand",
        highlightFontSize: "23",
        highlightColor: "#d9db38",
        highlightFont: "Quicksand",
        highlightBgColor: "#f1f1f2",

        queryVar: "",
        fallback: ""
      }
    },
    successModal: {
      visible: false,
      id: ""
    },
    loading: false,
    saving: false,
    edit: false
  };

  componentDidMount() {
    const elementid = this.props.match.params.elementid;
    const edit = this.props.editTimer === true ? true : false;
    if (elementid && elementid.match(/[a-fA-F0-9]{24}/)) {
      this.setState(
        {
          loading: true
        },
        () => {
          newElement(this.props.match.params.domid, elementid).then(res => {
            const updates = { loading: false };
            if (res.data.success === 1) {
              updates.element = res.data.element;
            }
            if (edit) {
              updates.edit = edit;
            }
            this.setState(updates);
          });
        }
      );
    }
  }

  addTrigger = title => {
    if (title === "Include") {
      this.setState(state => ({
        element: {
          ...state.element,
          element_displayurl: [
            ...this.state.element.element_displayurl,
            { id: Date.now(), type: "CONTAINS", data: "" }
          ]
        }
      }));
    } else if (title === "Exclude") {
      this.setState(state => ({
        element: {
          ...state.element,
          element_excludeurl: [
            ...this.state.element.element_excludeurl,
            { id: Date.now(), type: "CONTAINS", data: "" }
          ]
        }
      }));
    } else if (title === "Referrer") {
      this.setState(state => ({
        element: {
          ...state.element,
          element_referrer: [
            ...this.state.element.element_referrer,
            { id: Date.now(), type: "CONTAINS", data: "" }
          ]
        }
      }));
    }
  };

  deleteTrigger = (id, title) => {
    if (title === "Include") {
      if (this.state.element.element_displayurl.length > 1) {
        const newTrigger = this.state.element.element_displayurl.filter(
          l => l.id !== id
        );
        this.setState(state => ({
          element: {
            ...state.element,
            element_displayurl: newTrigger
          }
        }));
      }
    } else if (title === "Exclude") {
      const newTrigger = this.state.element.element_excludeurl.filter(
        l => l.id !== id
      );
      this.setState(state => ({
        element: {
          ...state.element,
          element_excludeurl: newTrigger
        }
      }));
    } else if (title === "Referrer") {
      const newTrigger = this.state.element.element_referrer.filter(
        l => l.id !== id
      );
      this.setState(state => ({
        element: {
          ...state.element,
          element_referrer: newTrigger
        }
      }));
    }
  };

  setValue = (key, val) => {
    const currentState = { ...this.state };
    this.setState(set(currentState, key, val));
  };

  validateGeneralSettings = (cb = () => {}) => {
    const element = this.state.element;
    const errors = {};
    if (element.element_name === "") {
      errors.element_name = "Please enter a valid timer name!";
    }
    if (element.element_data.timerType === "date") {
      if (element.element_data.endDateTime === "") {
        errors.endDateTime = "This is a required field!";
      }
    }
    if (element.element_data.postExpiryAction === "redirect") {
      if (element.element_data.postExpiryRedirectUrl === "") {
        errors.postExpiryRedirectUrl = "This is a required field!";
      }
    }
    if (
      element.element_data.timerType === "cookie" ||
      element.element_data.timerType === "evergreen"
    ) {
      if (element.element_data.days === undefined) {
        errors.days = "This is a required field!";
      }
      if (element.element_data.hours === undefined) {
        errors.hours = "This is a required field!";
      }
      if (element.element_data.minutes === undefined) {
        errors.minutes = "This is a required field!";
      }
      if (element.element_data.seconds === undefined) {
        errors.seconds = "This is a required field!";
      }
    }
    if (element.element_data.title === "") {
      errors.title = "Please enter a title for this timer!";
    }
    if (element.element_data.labels.days === "") {
      errors.labelDays = "Please enter a label for the days field!";
    }
    if (element.element_data.labels.hours === "") {
      errors.labelHours = "Please enter a label for the hour field!";
    }
    if (element.element_data.labels.minutes === "") {
      errors.labelMinutes = "Please enter a label for the minutes field!";
    }
    if (element.element_data.labels.seconds === "") {
      errors.labelSeconds = "Please enter a label for the seconds field!";
    }

    const updates = {
      errors
    };

    if (Object.keys(errors.length > 0)) {
      updates.activeTab = "1";
    }

    this.setState(updates, () => {
      if (Object.keys(errors).length === 0) {
        cb();
      }
    });
  };

  saveGeneralSettings = () => {
    this.validateGeneralSettings(() => {
      this.setState({
        activeTab: "2"
      });
    });
  };

  validateTriggerSettings = (cb = () => {}) => {
    const element = this.state.element;
    const errors = {};
    element.element_displayurl.map(l =>
      l.data === ""
        ? (errors.element_displayurl = "Please enter the details for all URLs")
        : ""
    );

    if (element.element_excludeurl.length >= 1) {
      element.element_excludeurl.map(x =>
        x.data === ""
          ? (errors.element_excludeurl =
              "Please enter the details for all URLs")
          : ""
      );
    }
    if (element.element_referrer.length >= 1) {
      element.element_referrer.map(r =>
        r.data === ""
          ? (errors.element_referrer = "Please enter the details for all URLs")
          : ""
      );
    }

    const updates = {
      errors
    };

    if (Object.keys(errors.length > 0)) {
      updates.activeTab = "2";
    }

    this.setState(updates, () => {
      if (Object.keys(errors).length === 0) {
        cb();
      }
    });
  };

  saveData = () => {
    this.validateGeneralSettings(() => {
      this.validateTriggerSettings(() => {
        const data = {
          element: this.state.element,
          domid: this.props.match.params.domid
        };
        if (this.state.edit) {
          data.elementid = this.props.match.params.elementid;
        }
        this.setState(
          {
            saving: true
          },
          () => {
            axios({
              url: "/elements/save_element",
              method: "post",
              data
            })
              .then(res => {
                this.setState({
                  saving: false
                });
                return res;
              })
              .then(res => {
                if (res.data.success === 1) {
                  this.setState({
                    successModal: {
                      ...this.state.successModal,
                      visible: true,
                      id: res.data.id
                    }
                  });
                } else {
                  message.error(res.data.msg);
                }
              })
              .catch(e => {
                console.log(e);
                message.error(e.message);
              });
          }
        );
      });
    });
  };

  closeSuccessModal = () => {
    this.props.history.push(`/${this.props.match.params.domid}/timers`);
  };

  render() {
    return (
      <div className="create-new-timer">
        <MainHeadingBar title="New Timer" cta={<div />} {...this.props} />
        <div className="timerContent">
          <Card className="timer-tabs">
            {this.state.loading ? (
              <Skeleton active />
            ) : (
              <Tabs
                tabPosition="left"
                activeKey={this.state.activeTab}
                onChange={ac => this.setState({ activeTab: ac })}
              >
                <TabPane tab="General Setting" key="1">
                  <GeneralSetting
                    {...this.props}
                    setValue={this.setValue}
                    data={this.state.element}
                    errors={this.state.errors}
                    saveGeneralSettings={this.saveGeneralSettings}
                  />
                </TabPane>
                <TabPane tab="Trigger" key="2">
                  <Trigger
                    {...this.props}
                    setValue={this.setValue}
                    data={this.state.element}
                    addTrigger={this.addTrigger}
                    deleteTrigger={this.deleteTrigger}
                    saveData={this.saveData}
                    saving={this.state.saving}
                    errors={this.state.errors}
                  />
                </TabPane>
              </Tabs>
            )}
          </Card>
          <Modal
            title={null}
            footer={null}
            visible={this.state.successModal.visible}
            onOk={this.closeSuccessModal}
            onCancel={this.closeSuccessModal}
          >
            <div style={{ textAlign: "center" }}>
              <Icon
                type="check-circle"
                theme="filled"
                style={{ color: "#60d155", fontSize: "5rem" }}
              />
              <br />
              <br />
              <h3>Timer Created Successfully!</h3>
              <p>Paste this code wherever you want to display the timer:</p>
              <p style={{ background: "#f1f1f2", padding: "15px 5px" }}>
                <code style={{ wordBreak: "break-all" }}>
                  {`<div class="timerly_clock_container_${
                    this.state.successModal.id
                  }"></div>`}
                </code>
              </p>
              <p>
                <ClickToCopy
                  textToCopy={`<div class="timerly_clock_container_${
                    this.state.successModal.id
                  }"></div>`}
                >
                  <Icon type="copy" /> Copy
                </ClickToCopy>
              </p>
              <Button
                className="btn-green"
                type="success"
                onClick={this.closeSuccessModal}
              >
                Continue <Icon type="arrow-right" />
              </Button>
            </div>
          </Modal>
          <div className="timer Preview" />
        </div>
      </div>
    );
  }
}
